.lds-grid {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
}

.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: indigo;
    animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}

@keyframes lds-grid {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.detailBanner {
    object-fit: cover;
    display: flex;
    height: 45vh;
}

.detailImg {
    background-size: cover;
    background-position: center;
    width: 80vw;
    height: 45vh;
    position: absolute;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px;
    box-shadow: 0px 0px 5px 2px slategrey;
}

.postDetail {
    display: flex;
    justify-content: space-between;
    font-family: 'Gafata', sans-serif;
}

.jumbotron {
    background: white;
    /* background-color: #21D4FD;
    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%); */
    border-radius: 30px;
    font-family: 'Gafata', sans-serif;
}

.applause {
    background-color: #FBAB7E;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    position: absolute;
    right: 10%;
    top: 35%;
    transform: translate(-35%, -10%);
}

.badgeCount {
    position: absolute;
    right: -23%;
    top: -3%;
    transform: translate(3%, 23%);
}

@media(max-width: 1024px) {
    .detailImg {
        opacity: 1;
        width: 100vw;
        height: 35vh;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .applause {
        position: absolute;
        right: 0%;
        top: 32.5%;
        transform: translate(-32.5%, -0%);
    }
}