.home {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}