.imgBanner {
    height: 25vh;
    z-index: 1;
    background: rgb(22, 15, 22);
    background: radial-gradient(circle, rgba(22, 15, 22, 1) 1%, rgba(0, 29, 36, 1) 24%, rgba(33, 12, 12, 0.4181022750897234) 54%, rgba(14, 33, 22, 1) 75%, rgba(0, 33, 36, 1) 93%);
}

.imgBanner h3 {
    color: white;
    font-size: 50px;
    text-align: center;
    line-height: 12.5vh;
    font-family: 'Nerko One', cursive;
}

.imgPro {
    object-fit: cover;
    z-index: 2;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    width: 200px;
    height: 200px;
}

@media(max-width: 400px) {
    .imgPro {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -20%);
        width: 150px;
        height: 150px;
    }
    .imgBanner h3 {
        font-size: 30px;
    }
}

.blogs {
    margin-top: 120px;
    padding-top: 10;
}



.card-columns {
    @include media-breakpoint-only(lg) {
        column-count: 4;
    }
    @include media-breakpoint-only(xl) {
        column-count: 5;
    }
}